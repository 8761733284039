






import Vue from 'vue'
import Page from '@/components/layouts/page.vue'

export default Vue.extend({
  name: 'Dummy',
  components: {
    Page
  }
})
